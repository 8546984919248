import BasiaFace from 'assets/images/About/people/basia.png';
import BlazejFace from 'assets/images/About/people/blazej.png';
import KrystianFace from 'assets/images/About/people/krystian.png';
import MajaFace from 'assets/images/About/people/maja.png';
import CzechFace from 'assets/images/About/people/tomasz_cz.png';
import ConstiFace from 'assets/images/About/people/consti.png';

import React from 'react';
import { B3, H2 } from 'styles/Typography.styled';

export const aboutItems = [
    {
        content: (
            <>
                <B3 mb={1.5}>We're over</B3>
                <H2 mb={2}>15+</H2>
                <B3>
                    years of market
                    <br />
                    experience
                </B3>
            </>
        ),

        key: 'devices',
    },
    {
        content: (
            <>
                <B3 mb={2}>Over</B3>
                <H2 mb={2}>13</H2>
                <B3>
                    AI products deployed
                    <br />
                    over 2 years
                </B3>
            </>
        ),

        key: 'countries',
    },
    {
        content: (
            <>
                <B3 mb={2}>Over</B3>
                <H2 mb={2}>90%</H2>
                <B3>
                    of our clients <br />
                    are from USA and EU
                </B3>
            </>
        ),

        key: 'ai',
    },
    {
        content: (
            <>
                <B3 mb={2}>Over</B3>
                <H2 mb={2}>30 000</H2>
                <B3>
                    devices use our <br />
                    software solutions <br />
                </B3>
            </>
        ),

        key: 'clients',
    },
];

export const peopleItems = [
    {
        src: KrystianFace,
        key: 'Krystian',
        fullName: 'Krystian Chwał',
        position: 'Chief Financial Officer',
    },
    {
        src: MajaFace,
        key: 'Maja',
        fullName: 'Maja Szymańska',
        position: 'Project Manager',
    },
    {
        src: BlazejFace,
        key: 'Blazej',
        fullName: 'Błażej Dziubek',
        position: 'Project Manager',
    },
    {
        src: BasiaFace,
        key: 'Barbara',
        fullName: 'Barbara Warchułka',
        position: 'HR Manager',
    },
    {
        src: CzechFace,
        key: 'Tomasz',
        fullName: 'Tomasz Czech',
        position: 'Head of QA',
    },
    {
        src: ConstiFace,
        key: 'Popescu',
        fullName: 'Popescu Constantin Alin',
        position: 'Sales Manager',
    },
    // {
    //     src: JoannaFace,
    //     key: 'Joanna',
    //     fullName: 'Joanna Mastalerz',
    //     position: 'Office Manager',
    // },
];
